@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPMincho:wght@400;700&family=Klee+One&family=Kosugi+Maru&family=M+PLUS+1p:wght@400;700;900&family=Noto+Sans+JP:wght@400;700;900&family=Noto+Serif+JP:wght@400;700;900&family=Shippori+Mincho:wght@400;700&family=Zen+Kaku+Gothic+New:wght@400;700;900&display=swap');

.CardSlider_c-card-slider__asdZ8 {
  width: 100%;
  position: relative;
}
.CardSlider_c-card-slider__list__fJTPc {
  width: 100%;
  padding: 16px 32px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.CardSlider_c-card-slider__list__fJTPc.CardSlider_center__MhhmO {
  justify-content: center;
}
.CardSlider_c-card-slider__asdZ8.CardSlider_center__MhhmO .CardSlider_c-card-slider__list__fJTPc {
  justify-content: center;
}
.CardSlider_c-card-slider__list__item__N39j9 {
  width: 300px;
  margin-right: 32px;
  flex-shrink: 0;
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
}
.CardSlider_c-card-slider__prev__YtPjR,
.CardSlider_c-card-slider__next__G6X_S {
  width: 96px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
}
.CardSlider_c-card-slider__prev__YtPjR button,
.CardSlider_c-card-slider__next__G6X_S button {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}
.CardSlider_c-card-slider__prev__YtPjR button::after,
.CardSlider_c-card-slider__next__G6X_S button::after {
  content: '';
  width: 16px;
  height: 16px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  top: 22px;
  left: 24px;
}
.CardSlider_c-card-slider__prev__YtPjR {
  left: 0;
}
.CardSlider_c-card-slider__next__G6X_S {
  right: 0;
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}
@media screen and (max-width: 768px) {
  .CardSlider_c-card-slider__asdZ8.CardSlider_center__MhhmO .CardSlider_c-card-slider__list__fJTPc {
    justify-content: flex-start;
  }
  .CardSlider_c-card-slider__list__fJTPc {
    width: 100%;
    padding: 16px 16px;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
  }
  .CardSlider_c-card-slider__list__item__N39j9 {
    margin-right: 16px;
  }
  .CardSlider_c-card-slider__list__item__N39j9:last-child {
    width: calc(300px + 16px);
    padding-right: 16px;
  }
  .CardSlider_c-card-slider__prev__YtPjR,
  .CardSlider_c-card-slider__next__G6X_S {
    display: none;
  }
}

.TourList_base-sectionItem__doUzQ {
  padding: 32px 0;
}
.TourList_base-sectionItem__inner__61bRK {
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.TourList_base-sectionItem__inner__61bRK.TourList_nofilter__PELDW {
  justify-content: center;
}
.TourList_base-sectionItem__inner__filter__flg__6FEF9 {
  display: none;
}
.TourList_base-sectionItem__inner__filter__mxIZA {
  display: none;
}
.TourList_base-sectionItem__inner__filter__bg__9HZjr {
  display: none;
}
.TourList_base-sectionItem__inner__side__A2VT8 {
  width: 260px;
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
}
.TourList_base-sectionItem__inner__main__VFR2u {
  width: calc(100% - 260px - 32px);
}
.TourList_base-sectionItem__inner__main__header__mrCWT {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.TourList_base-sectionItem__inner__main__header__select__MedfF {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
}
.TourList_base-sectionItem__inner__main__header__select__MedfF p {
  font-weight: bold;
}
.TourList_base-sectionItem__inner__main__header__select__MedfF label {
  width: 200px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 4px;
  overflow: hidden;
  display: block;
  position: relative;
}
.TourList_base-sectionItem__inner__main__header__select__MedfF label::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  margin-left: 8px;
  position: absolute;
  top: calc(50% - 2px);
  right: 8px;
}
.TourList_base-sectionItem__inner__main__header__select__MedfF select {
  width: 100%;
  height: 100%;
  background: #fff;
  background: var(--theme-background-color);
  padding: 0 24px 0 8px;
}
.TourList_base-sectionItem__inner__main__body__PFWlA {
}
.TourList_base-sectionItem__inner__main__body__list__54p24 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.TourList_base-sectionItem__inner__main__body__list__item__K8PA1 {
  width: calc((100% - 32px) / 3);
  margin-bottom: 32px;
}
.TourList_base-sectionItem__inner__main__body__list__item__K8PA1:not(:nth-child(3n)) {
  margin-right: 16px;
}
.TourList_base-sectionItem__inner__main__body__btn__XqlmS {
  width: 300px;
  height: 48px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  color: #fff;
  margin: 32px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TourList_c-card-filter__lhH8i {
  width: 100%;
  max-width: 480px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  background: var(--theme-content-section-background-color);
  overflow: hidden;
}
.TourList_c-card-filter__headline__To9HU {
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TourList_c-card-filter__box__nvgXa {
  padding: 8px;
  position: relative;
}
.TourList_c-card-filter__box__nvgXa + .TourList_c-card-filter__box__nvgXa {
  border-top: 1px solid #ddd;
}
.TourList_c-card-filter__box__ttl__qtGjd {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  color: var(--theme-primary-color);
}
.TourList_c-card-filter__box__list__U9Q5S {
  position: relative;
}
.TourList_c-card-filter__box__list__U9Q5S.TourList_is-close__wAL50 {
  max-height: 240px;
  overflow: hidden;
}
.TourList_c-card-filter__box__list__item__Z5Hvu {
}
.TourList_c-card-filter__box__list__item__Z5Hvu label {
  width: 100%;
  min-height: 32px;
  padding: 6px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.TourList_c-card-filter__box__list__item__Z5Hvu label > input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.TourList_c-card-filter__box__list__item__Z5Hvu label > div {
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  margin-right: 8px;
}
.TourList_c-card-filter__box__list__item__Z5Hvu label > div span {
  width: 100%;
  height: 100%;
  position: relative;
  display: none;
}
.TourList_c-card-filter__box__list__item__Z5Hvu label > div span::after {
  content: '';
  display: block;
  width: 4px;
  height: 9px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 3px;
}
.TourList_c-card-filter__box__list__item__Z5Hvu label > input:checked + div span {
  display: block;
}
.TourList_c-card-filter__box__list__item__Z5Hvu label > p {
  width: calc(100% - 16px - 4px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.TourList_c-card-filter__box__more__cV4X1 {
  width: 100%;
  height: 48px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  bottom: 8px;
}
.TourList_c-card-filter__box__more__cV4X1.TourList_is-close__wAL50 {
  position: absolute;
}
.TourList_c-card-filter__box__more__cV4X1.TourList_is-open__MVpNT {
  position: relative;
}
.TourList_c-card-filter__box__more__btn__lk8UV {
  width: 160px;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TourList_c-card-filter__btn__OPhrB {
  width: calc(100% - 32px);
  height: 56px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  color: #fff;
  margin: 32px auto;
  justify-content: center;
  align-items: center;
  display: none;
}

.TourList_form-calendar-label____UG_ {
  padding-bottom: 5px !important;
}
.TourList_form-calendar__8A5wO {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #000;
  border-radius: 4px;
  display: block;
  position: relative;
  margin-bottom: 8px;
  cursor: pointer;
}
.TourList_form-calendar__8A5wO input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 4px 0 4px 8px; 
  background: #fff;
}
.TourList_form-calendar__8A5wO input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.TourList_form-calendar__8A5wO img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.TourList_form-calendar-input-container__d0fhv {
  max-width: 240px;
}

@media screen and (max-width: 768px) {
  .TourList_base-sectionItem__inner__61bRK {
    padding: 0;
    flex-direction: column;
  }
  .TourList_base-sectionItem__inner__main__VFR2u {
    width: calc(100% - 240px - 16px);
  }
  .TourList_base-sectionItem__inner__filter__mxIZA {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: 16px;
    margin-bottom: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    z-index: 1;
  }
  .TourList_base-sectionItem__inner__filter__mxIZA img {
    width: 24px;
    height: 24px;
  }
  .TourList_base-sectionItem__inner__filter__bg__9HZjr {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 101;
    display: none;
  }
  .TourList_base-sectionItem__inner__filter__flg__6FEF9:checked ~ .TourList_base-sectionItem__inner__side__A2VT8 {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .TourList_base-sectionItem__inner__filter__flg__6FEF9:checked ~ .TourList_base-sectionItem__inner__filter__bg__9HZjr {
    display: block;
  }
  .TourList_base-sectionItem__inner__side__A2VT8 {
    width: 300px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 102;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  }
  .TourList_base-sectionItem__inner__main__VFR2u {
    width: 100%;
    padding: 0 16px;
  }
  .TourList_base-sectionItem__inner__main__header__mrCWT {
    flex-direction: column;
    align-items: flex-start;
  }
  .TourList_base-sectionItem__inner__main__header__select__MedfF {
    width: 100%;
    margin-top: 16px;
  }
  .TourList_base-sectionItem__inner__main__header__select__MedfF p {
    width: 64px;
  }
  .TourList_base-sectionItem__inner__main__header__select__MedfF label {
    width: calc(100% - 64px);
  }
  .TourList_c-card-filter__lhH8i {
    width: 300px;
    height: 100vh;
    border-radius: 0;
    border: 0;
    padding-bottom: 64px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .TourList_c-card-filter__box__nvgXa {
    padding: 16px;
  }
  .TourList_c-card-filter__box__nvgXa:last-of-type {
    border-bottom: 1px solid #ddd;
  }
  .TourList_c-card-filter__btn__OPhrB {
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  .TourList_base-sectionItem__inner__main__body__list__item__K8PA1 {
    width: calc((100% - 16px) / 2);
  }
  .TourList_base-sectionItem__inner__main__body__list__item__K8PA1 {
    width: 100%;
  }
  .TourList_base-sectionItem__inner__main__body__list__item__K8PA1:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .TourList_base-sectionItem__inner__main__body__list__item__K8PA1:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .TourList_base-sectionItem__inner__main__body__list__item__K8PA1:nth-child(odd) {
    margin-right: 0;
  }
}

.Header_c-header__JU9zq {
  position: relative;
  z-index: 100;
}
.Header_c-header__inner___QsN1 {
  width: 100%;
  height: 80px;
  padding: 0 32px;
  background: #fff;
  background: var(--theme-header-background-color);
  box-shadow: 0 2px 4px rgba(60, 64, 67, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.Header_c-header__ttl__KltFq {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Header_c-header__ttl__logo__SrSiA {
  height: 40px;
}
.Header_c-header__ttl__logo__SrSiA img {
  width: auto;
  height: inherit;
  object-fit: contain;
}
.Header_c-header__lang__drgHn.Header_base-select__1p53o {
    width: 160px;
  }
.Header_c-header__lang__drgHn {
  width: 128px;
}
.Header_c-header__lang__ic__REsOy {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.Header_c-header__nav__uHmX6 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Header_c-header__nav__list__36hog {
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Header_c-header__nav__list__item__SSnAs {
}
.Header_c-header__nav__list__item__childrenMenu__fTwwt {
  position: relative;
}
.Header_c-header__nav__list__item__SSnAs > a,
.Header_c-header__nav__list__item__childrenMenu__fTwwt > p {
  font-weight: bold;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.Header_c-header__nav__list__item__childrenMenu__fTwwt > p::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin-left: 8px;
}
.Header_c-header__nav__list__item__childrenMenu__fTwwt > ul {
  display: none;
  width: 320px;
  /* max-height: 400px; */
  background: #fff;
  background: var(--theme-header-background-color);
  padding: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 21px;
  left: calc(-50% - 64px);
  overflow-y: auto;
}
.Header_c-header__nav__list__item__childrenMenu__fTwwt:hover ul {
  display: block;
}
.Header_c-header__nav__list__item__childrenMenu__fTwwt li {
}
.Header_c-header__nav__list__item__childrenMenu__fTwwt a {
  width: 100%;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.Header_c-header__menu__oUr5_ {
  display: none;
}
.Header_c-header__nav__list__item__SSnAs > a:hover,
.Header_c-header__nav__list__item__childrenMenu__fTwwt > p:hover,
.Header_c-header__nav__list__item__childrenMenu__fTwwt a:hover {
  opacity: 0.8;
}
.Header_c-header__fixed__zDiMq {
  overflow: hidden;
}
@media screen and (max-width: 820px) {
  .Header_c-header__JU9zq {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .Header_c-header__h1__cih7D {
    font-size: 10px;
    background: #fff;
    padding: 4px 8px;
    position: static;
  }
  .Header_c-header__inner___QsN1 {
    height: 56px;
    padding: 0 0 0 8px;
  }
  .Header_c-header__ttl__KltFq {
    width: auto;
    height: 32px;
    margin-top: 0;
  }
  .Header_c-header__flg__k03wT {
    display: none;
  }
  .Header_c-header__menu__oUr5_ {
    width: 56px;
    height: 56px;
    position: relative;
    display: block;
  }
  .Header_c-header__menu__oUr5_::before,
  .Header_c-header__menu__oUr5_::after,
  .Header_c-header__menu__oUr5_ > span {
    width: 24px;
    height: 2px;
    background: #333;
    position: absolute;
    left: 16px;
  }
  .Header_c-header__menu__oUr5_::before {
    content: '';
    top: 19px;
    transition: all 0.4s ease;
  }
  .Header_c-header__menu__oUr5_ > span {
    top: 27px;
  }
  .Header_c-header__menu__oUr5_::after {
    content: '';
    top: 36px;
    transition: all 0.4s ease;
  }
  .Header_c-header__flg__k03wT:checked + .Header_c-header__menu__oUr5_::before {
    top: 27px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .Header_c-header__flg__k03wT:checked + .Header_c-header__menu__oUr5_ > span {
    opacity: 0;
  }
  .Header_c-header__flg__k03wT:checked + .Header_c-header__menu__oUr5_::after {
    top: 27px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .Header_c-header__flg__k03wT:checked ~ .Header_c-header__nav__uHmX6 {
    /* Fallback for browsers not supporting dvh */
    max-height: calc(100vh - 56px);
    height: 100vh;
    max-height: calc(100dvh - 56px);
    height: 100dvh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .Header_c-header__nav__uHmX6 {
    width: 100%;
    background: #f9f9f9;
    position: absolute;
    top: 56px;
    left: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease;
    display: block;
  }
  .Header_c-header__nav__list__36hog {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }
  .Header_c-header__nav__list__item__SSnAs {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 16px;
  }
  .Header_c-header__nav__list__item__SSnAs > a {
    border-top: 1px solid #ddd;
  }
  .Header_c-header__nav__list__item__SSnAs a {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    background: #fff;
    position: relative;
  }
  .Header_c-header__nav__list__item__SSnAs a::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ddd;
    border-right: 2px solid #ddd;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 7px);
    right: 8px;
  }
  .Header_c-header__nav__list__item__childrenMenu__fTwwt {
  }
  .Header_c-header__nav__list__item__childrenMenu__fTwwt p {
    line-height: 32px;
    padding: 0 16px;
  }
  .Header_c-header__nav__list__item__childrenMenu__fTwwt p::after {
    display: none;
  }
  .Header_c-header__nav__list__item__childrenMenu__fTwwt p:hover {
    opacity: 1;
  }
  .Header_c-header__nav__list__item__childrenMenu__fTwwt > ul {
    display: block;
    width: 100%;
    max-height: auto;
    border-width: 0;
    box-shadow: none;
    position: static;
    padding: 0;
  }
  .Header_c-header__nav__list__item__childrenMenu__fTwwt li {
    border-top: 1px solid #ddd;
  }
  .Header_c-header__lang__drgHn {
    margin: 32px auto 160px auto;
  }
}

.BookingWidgetHeader_c-header__8erMj {
  position: relative;
  z-index: 100;
  background: var(--theme-header-background-color);
}
.BookingWidgetHeader_c-header__inner__FcUgs {
  width: 100%;
  height: 64px;
  padding: 0 32px;
  /* background: #fff; */
  box-shadow: 0 2px 4px rgba(60, 64, 67, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.BookingWidgetHeader_c-header__ttl__os4No {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.BookingWidgetHeader_c-header__ttl__logo__3JKjC {
  /* min-width: 32px;
  height: 32px; */
  border-right: 1px solid #ddd;
  padding-right: 16px;
  margin-right: 16px;
}
.BookingWidgetHeader_c-header__ttl__logo__3JKjC img {
  /* width: auto;
  height: inherit; */
  min-width: 32px;
  max-width: 96px;
  height: 32px;
}
.BookingWidgetHeader_c-header__ttl__name__7DZLp {
  font-size: 20px;
  font-weight: bold;
}
.BookingWidgetHeader_c-header__lang__DK3UN.BookingWidgetHeader_base-select__76HoF {
    width: 160px;
  }
.BookingWidgetHeader_c-header__lang__DK3UN {
  width: 128px;
}
.BookingWidgetHeader_c-header__lang__ic___FzQj {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.BookingWidgetHeader_c-header__nav__vDs7y {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.BookingWidgetHeader_c-header__nav__list__d5AaM {
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.BookingWidgetHeader_c-header__nav__list__item__0lF9B {
}
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM {
  position: relative;
}
.BookingWidgetHeader_c-header__nav__list__item__0lF9B > a,
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM > p {
  font-weight: bold;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM > p::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin-left: 8px;
}
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM > ul {
  display: none;
  width: 320px;
  /* max-height: 400px; */
  padding: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
  background: #fff;
  background: var(--theme-header-background-color);
  position: absolute;
  top: 21px;
  left: calc(-50% - 64px);
  overflow-y: auto;
}
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM:hover ul {
  display: block;
}
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM li {
}
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM a {
  width: 100%;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.BookingWidgetHeader_c-header__menu__tH3LZ {
  display: none;
}
.BookingWidgetHeader_c-header__nav__list__item__0lF9B > a:hover,
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM > p:hover,
.BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM a:hover {
  opacity: 0.8;
}
.BookingWidgetHeader_c-header__fixed__IxOZu {
  overflow: hidden;
}
@media screen and (max-width: 820px) {
  .BookingWidgetHeader_c-header__8erMj {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .BookingWidgetHeader_c-header__h1__ahkPw {
    font-size: 10px;
    background: #fff;
    padding: 4px 8px;
    position: static;
  }
  .BookingWidgetHeader_c-header__inner__FcUgs {
    height: 56px;
    padding: 0 0 0 8px;
  }
  .BookingWidgetHeader_c-header__ttl__os4No {
    width: auto;
    height: 32px;
    margin-top: 0;
  }
  .BookingWidgetHeader_c-header__flg__XV3oM {
    display: none;
  }
  .BookingWidgetHeader_c-header__menu__tH3LZ {
    width: 56px;
    height: 56px;
    position: relative;
    display: block;
  }
  .BookingWidgetHeader_c-header__menu__tH3LZ::before,
  .BookingWidgetHeader_c-header__menu__tH3LZ::after,
  .BookingWidgetHeader_c-header__menu__tH3LZ > span {
    width: 24px;
    height: 2px;
    background: #333;
    position: absolute;
    left: 16px;
  }
  .BookingWidgetHeader_c-header__menu__tH3LZ::before {
    content: '';
    top: 19px;
    transition: all 0.4s ease;
  }
  .BookingWidgetHeader_c-header__menu__tH3LZ > span {
    top: 27px;
  }
  .BookingWidgetHeader_c-header__menu__tH3LZ::after {
    content: '';
    top: 36px;
    transition: all 0.4s ease;
  }
  .BookingWidgetHeader_c-header__flg__XV3oM:checked + .BookingWidgetHeader_c-header__menu__tH3LZ::before {
    top: 27px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .BookingWidgetHeader_c-header__flg__XV3oM:checked + .BookingWidgetHeader_c-header__menu__tH3LZ > span {
    opacity: 0;
  }
  .BookingWidgetHeader_c-header__flg__XV3oM:checked + .BookingWidgetHeader_c-header__menu__tH3LZ::after {
    top: 27px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .BookingWidgetHeader_c-header__flg__XV3oM:checked ~ .BookingWidgetHeader_c-header__nav__vDs7y {
    /* Fallback for browsers not supporting dvh */
    max-height: calc(100vh - 79px);
    height: 100vh;
    max-height: calc(100dvh - 79px);
    height: 100dvh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .BookingWidgetHeader_c-header__flg__XV3oM:checked ~ .BookingWidgetHeader_c-header__nav-scrolled___m25V {
    /* Fallback for browsers not supporting dvh */
    max-height: calc(100vh - 56px);
    max-height: calc(100dvh - 56px);
  }
  .BookingWidgetHeader_c-header__nav__vDs7y {
    width: 100%;
    background: #f9f9f9;
    position: absolute;
    top: 56px;
    left: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease;
    display: block;
  }
  .BookingWidgetHeader_c-header__nav__list__d5AaM {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }
  .BookingWidgetHeader_c-header__nav__list__item__0lF9B {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 16px;
  }
  .BookingWidgetHeader_c-header__nav__list__item__0lF9B > a {
    border-top: 1px solid #ddd;
  }
  .BookingWidgetHeader_c-header__nav__list__item__0lF9B a {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    background: #fff;
    position: relative;
  }
  .BookingWidgetHeader_c-header__nav__list__item__0lF9B a::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ddd;
    border-right: 2px solid #ddd;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 7px);
    right: 8px;
  }
  .BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM {
  }
  .BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM p {
    line-height: 32px;
    padding: 0 16px;
  }
  .BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM p::after {
    display: none;
  }
  .BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM p:hover {
    opacity: 1;
  }
  .BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM > ul {
    display: block;
    width: 100%;
    max-height: auto;
    border-width: 0;
    box-shadow: none;
    position: static;
    padding: 0;
  }
  .BookingWidgetHeader_c-header__nav__list__item__childrenMenu__HwPbM li {
    border-top: 1px solid #ddd;
  }
  .BookingWidgetHeader_c-header__lang__DK3UN {
    margin: 32px auto 160px auto;
  }
  .BookingWidgetHeader_c-header__ttl__name__7DZLp {
    font-size: 16px;
    line-height: 18px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.BookingWidgetHeader_scrolled__zG_rw {
  max-height: calc(100dvh - 56px);
  height: 100dvh;
}

